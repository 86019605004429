import { GetOrg } from '@endaoment-frontend/api';
import { RequestHandler } from '@endaoment-frontend/data-fetching';
import {
  grantSchema,
  isUuid,
  type DonationRecipient,
  type GrantInstructionInput,
  type UUID,
} from '@endaoment-frontend/types';

type InitiateAsyncGrantInput = {
  originFundId: UUID;
  recipient: DonationRecipient;
  amount: bigint;
  offsetAmount?: bigint;
  instructions: GrantInstructionInput;
  recommendationId?: UUID;
  idempotencyKey: string;
};
type InitiateAsyncGrantBody = {
  originFundId: string;
  destinationOrgId: string;
  destinationSubprojectId?: string;
  requestedAmount: string;
  purpose: string;
  recommender?: string;
  specialInstructions?: string;
  shareMyEmail?: boolean;
  recommendationIds?: Array<string>;
  idempotencyKey: string;
};

export const InitiateAsyncGrant = new RequestHandler(
  'InitiateAsyncGrant',
  fetch => async (input: InitiateAsyncGrantInput) => {
    if (input.recipient.type === 'fund') throw new TypeError('Granting to another fund is not supported');

    // Compute the org ID since it can be passed as either an EIN or an ID
    const orgId = isUuid(input.recipient.einOrId)
      ? input.recipient.einOrId
      : (await GetOrg.fetchFromDefaultClient([input.recipient.einOrId])).id;

    const res = await fetch('v1/transfers/async-grants', {
      method: 'POST',
      body: {
        originFundId: input.originFundId,
        destinationOrgId: orgId,
        destinationSubprojectId: input.recipient.subprojectId,
        requestedAmount: input.offsetAmount ? input.offsetAmount.toString() : input.amount.toString(),
        purpose: input.instructions.purpose,
        recommender: input.instructions.recommender,
        specialInstructions: input.instructions.specialInstructions,
        shareMyEmail: input.instructions.shareMyEmail,
        recommendationIds: input.recommendationId ? [input.recommendationId] : [],
        idempotencyKey: input.idempotencyKey,
      } satisfies InitiateAsyncGrantBody,
    });

    return grantSchema.parse(res);
  },
  {
    makeMockEndpoints: ({ baseURL }) => ({ default: `${baseURL}/v1/transfers/async-grants` }),
  },
);
