import type { MutationStatus } from '@tanstack/react-query';
import { P, match } from 'ts-pattern';
import { useChainId } from 'wagmi';

import { GetFund } from '@endaoment-frontend/api';
import { defaults } from '@endaoment-frontend/config';
import { calculateGrantableBalance } from '@endaoment-frontend/funds';
import { routes } from '@endaoment-frontend/routes';
import type { DonationRecipient, GrantInstructionInput, UUID } from '@endaoment-frontend/types';
import { Checkbox } from '@endaoment-frontend/ui/forms';
import { QuestionIcon } from '@endaoment-frontend/ui/icons';
import { Button, Loader, QuestionPopover } from '@endaoment-frontend/ui/shared';
import { EntityCardWithLabel } from '@endaoment-frontend/ui/smart';
import { formatBasisPointsToPercent, formatCurrency, formatUsdc } from '@endaoment-frontend/utils';

import wizardStyles from '../DonationWizard.module.scss';
import { RecommendButton } from '../common/RecommendButton';
import { useIsFundCollaborator } from '../useIsFundCollaborator';

import { useTransferFee } from './useTransferFee';

export const GrantConfirmationStep = ({
  originFundId,
  destination,
  grantAmount = 0n,
  grantInstructions,
  feeIsOffset,
  calculatedOffsetFeeAmount,
  grantStatus,
  recommendStatus,
  onSubmit,
  onRecommend,
  onRemoveOrigin,
  onRemoveDestination,
  onOffsetFees,
}: {
  originFundId: UUID;
  destination: DonationRecipient;
  grantInstructions: GrantInstructionInput;
  grantAmount?: bigint;
  calculatedOffsetFeeAmount?: bigint;
  feeIsOffset: boolean;
  grantStatus: MutationStatus;
  onSubmit: () => void;
  onRecommend: () => void;
  onRemoveOrigin?: () => void;
  onRemoveDestination?: () => void;
  onOffsetFees: () => void;
  recommendStatus: MutationStatus;
}) => {
  const { data: fund } = GetFund.useQuery([originFundId], { enabled: !!originFundId });
  const isUserFundCollaborator = useIsFundCollaborator(originFundId);

  const chainId = useChainId();
  const { data: computedTransferFee, isPending: isPendingGrantFee } = useTransferFee({
    sender: fund?.v2ContractAddress,
    recipient: destination,
    chainId,
    transferAmount: grantAmount,
    enabled: !!fund,
  });

  const grantFee = destination.type === 'org' ? computedTransferFee : 0n;

  const estimatedProceeds = grantAmount - grantFee;

  const canOffsetFees = fund && calculateGrantableBalance(fund) >= (calculatedOffsetFeeAmount ?? 0n);
  const canSubmit = estimatedProceeds > 0;

  return (
    <>
      <EntityCardWithLabel
        label='Granting from'
        entity={{ type: 'fund', id: originFundId }}
        onRemove={onRemoveOrigin}
        fundCardOptions={{ showBalance: false }}
      />
      <EntityCardWithLabel label='Granting to' entity={destination} onRemove={onRemoveDestination} />
      <hr />
      <div className={wizardStyles['donation-info']}>
        {!isPendingGrantFee ? (
          <>
            <div>
              <h4>Grant</h4>
              <h4>{formatCurrency(formatUsdc(grantAmount))}</h4>
            </div>
            <div>
              <h4>Estimated Proceeds</h4>
              <h4>{formatCurrency(formatUsdc(estimatedProceeds))}</h4>
            </div>
            {match(destination)
              .with({ type: 'fund' }, () => <></>)
              .with({ type: 'org' }, () => (
                <>
                  <div>
                    <QuestionPopover
                      content={
                        <>
                          {`A maximum fee of ${formatBasisPointsToPercent(
                            defaults.fees.transferBps,
                          )} is being applied. `}
                          <a href={routes.docs.feeSchedule()} target='_blank'>
                            Read More
                          </a>
                        </>
                      }>
                      <h6 className={wizardStyles['question-tooltip']}>
                        Endaoment Fee
                        <QuestionIcon color='currentColor' width={15} height={16} />
                      </h6>
                    </QuestionPopover>
                    <h6>{formatCurrency(formatUsdc(grantFee))}</h6>
                  </div>
                  <Checkbox
                    checked={feeIsOffset}
                    onChange={onOffsetFees}
                    label='Offset Fees'
                    disabled={!canOffsetFees || grantStatus !== 'idle'}
                    className={wizardStyles['offset-fees']}
                  />
                </>
              ))
              .exhaustive()}
          </>
        ) : (
          <Loader size='l' />
        )}
      </div>
      <hr />
      <div className={wizardStyles['instruction-info']}>
        <label>Recommender</label>
        <p>{grantInstructions.recommender}</p>
        {grantInstructions.specialInstructions ? (
          <>
            <label>Restricted Grant Instructions</label>
            <p>{grantInstructions.specialInstructions}</p>
          </>
        ) : (
          <>
            <label>Grant Purpose</label>
            <p>{grantInstructions.purpose}</p>
          </>
        )}
      </div>
      <div className={wizardStyles['send-transaction']}>
        {match(grantStatus)
          .when(
            // Case of a fund collaborator recommending a grant
            () => !!isUserFundCollaborator,
            () => <RecommendButton onRecommend={onRecommend} recommendStatus={recommendStatus} />,
          )
          .with(P.union('idle', 'error'), status => (
            <>
              <Button
                variation='purple'
                type='submit'
                filled
                onClick={onSubmit}
                size='medium'
                float={false}
                disabled={!canSubmit}>
                Recommend Grant
              </Button>
              {status === 'error' && <span>Something went wrong, try again</span>}
            </>
          ))
          // Show processing state since a successful grant will take the user to the next step
          .with(P.union('pending', 'success'), () => (
            <Button type='button' float={false} size='medium'>
              <Loader size='s' />
              Processing request...
            </Button>
          ))
          .exhaustive()}
        <p data-testid='grant-disclaimer'>
          By proceeding, you agree to Endaoment's&nbsp;<a href={routes.docs.privacyPolicy()}>privacy policy</a>
          &nbsp;and&nbsp;<a href={routes.docs.termsAndConditions()}>terms & conditions</a>
        </p>
      </div>
    </>
  );
};
